<template>
  <div class="policy" v-html="policy" />
</template>
<style>
.policy * {
  all: revert;
}
</style>
<script>
import AdminService from '@/services/AdminService';

export default {
  name: 'Policy',
  mounted() {
    this.loadPolicy();
  },
  data() {
    return {
      policy: null,
    }
  },
  methods: {
    async loadPolicy() {
      const { policy } = await AdminService.PoliciesAndTemplates.read();
      this.policy = policy ?? null;
    }
  }
}
</script>